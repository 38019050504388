ul {
  list-style: none; /* Remove default bullets */
  margin: 0;
  padding-left: 1rem;
}

ul li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  float: left;
  color: white; /* Change the color */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
