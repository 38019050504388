@media (max-width: 425px) {
  :root {
    --cube-width: 200px;
    --translateZ: 100px;
  }
}

@media (min-width: 426px) {
  :root {
    --cube-width: 300px;
    --translateZ: 150px;
  }
}

@media (min-width: 1600px) {
  :root {
    --cube-width: 500px;
    --translateZ: 250px;
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  background: #000;
}

header {
  color: #eee;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
}

.container {
  /* max-width: 1000px; */
  margin: 0 auto;
  width: 80vw;
}

header h1 {
  margin: 0;
  font-size: 100px;
}

header .sub-heading {
  font-size: 24px;
  margin: 0;
}

.cube-container {
  perspective: 1000px;
}

.cube-container .cube {
  width: var(--cube-width);
  height: var(--cube-width);
  transform-style: preserve-3d;
}
.cube-container .cube-face {
  -webkit-user-drag: 'none';
  user-select: 'none';
  width: var(--cube-width);
  height: var(--cube-width);
  text-decoration: none;
  position: absolute;
  font-size: 30px;
  background: yellow;
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(0.25px);
  transition: filter 0.3s ease;
}
.cube-container .cube-face:hover {
  filter: none;
}
.cube-container .front {
  background: #d63946;
  transform: translateZ(var(--translateZ));
}

.cube-container .back {
  background: #06d6a0;
  transform: rotateY(180deg) translateZ(var(--translateZ));
}

.cube-container .left {
  background: #023047;
  color: #fff;
  transform: rotateY(-90deg) translateZ(var(--translateZ));
}

.cube-container .right {
  background: #3d348b;
  color: #fff;
  transform: rotateY(90deg) translateZ(var(--translateZ));
}

.cube-container .top {
  background: #a8dadc;
  transform: rotateX(90deg) translateZ(var(--translateZ));
}

.cube-container .bottom {
  background: #ffd166;
  transform: rotateX(-90deg) translateZ(var(--translateZ));
}
